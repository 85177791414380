<script>
/* eslint-disable */
import { arraySucursales } from "@/constants/sucursales";
import moment from "moment";
import { RouterLink } from "vue-router";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  props: ["idTarjeta"],
  data() {
    return {
      botonHabilitado: true,
      nombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      telefono: "",
      correo: "",
      codigoPostal: 0,
      fechaNacimiento: "",
      tarjeta: "",
      puntos: 0,
      sexo: "",
      fechaAltat: "",
      dioalta: "",
      division: 0,
      promotor: false,
      fechaverfi: "",
      verificoemail: false,
      id_cli: 0,
      preregistro: "",
      recomendo: "",
      listaSucursales: [
        { nombre: "Sin división", division: 0 },
        ...arraySucursales.sucursalesSk,
        ...arraySucursales.sucursalesSkMicro,
        ...arraySucursales.sucursalesSurtitienda,
        ...arraySucursales.sucursalesAcropolis,
      ],
    };
  },
  created() {
    this.consultarDatosTarjeta();
  },
  computed: {
    ...mapState("autenticacion", [
      "usuarioActual",
    ]),
  },
  methods: {
    ...mapActions("validacion", [
      "consultarTarjetahabienteHasura",
      "editarTarjetahabienteHasuraManual",
      "editarTarjetahabienteFirebaseManual"
      //   "buscarTarjetahabienteFirebase",
      //   "consultarEmpleados",
    ]),
    async consultarDatosTarjeta() {
      try {
        const dataTarjeta = await this.consultarTarjetahabienteHasura(
        this.idTarjeta
      );

      if(!dataTarjeta){
        this.$swal({
          icon: "warning",
          type: "warning",
          title: "Tarjeta no encontrada",
          allowOutsideClick: false,
          confirmButtonText: "Volver a histórico",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.replace("/historico-tarjetas")
        }
      });
        return;
      }
      console.log(dataTarjeta);
      this.nombre = dataTarjeta.nombre;
      this.apellidoPaterno = dataTarjeta.apellidoP;
      this.apellidoMaterno = dataTarjeta.apellidoM;
      this.telefono = dataTarjeta.telefono;
      this.correo = dataTarjeta.email;
      this.codigoPostal = dataTarjeta.cp;
      this.fechaNacimiento = moment(dataTarjeta.fechanacim).format(
        "DD/MM/YYYY"
      );
      this.tarjeta = this.idTarjeta;
      this.puntos = dataTarjeta.saldoc;
      this.sexo = dataTarjeta.sexo;
      this.id_cli = dataTarjeta.id_cli;
      this.fechaAltat = moment(dataTarjeta.fechaalta).format("DD/MM/YYYY");
      this.dioalta = dataTarjeta.dioalta;
      this.division = dataTarjeta.division;
      this.promotor = dataTarjeta.promotor == "true" ? true : false;
      this.fechaverfi = moment(dataTarjeta.fechaverfi).format("DD/MM/YYYY");
      this.verificoemail = dataTarjeta.verificoemail == "true" ? true : false;
      this.preregistro = dataTarjeta.preregistro;
      this.recomendo = dataTarjeta.recomendo;
      } catch (error) {
        this.$swal({
          icon: error.code ? "error" : "warning",
          type: error.code ? "error" : "warning",
          title: error.code ? error.code : "Oops...",
          text: error.msg,
        });
      }
    },

    validarFormularioVerificacion() {
      this.botonHabilitado = false;
      const errores = [];
      const patronCorreo =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      const patronCodPostal = /^\d{5}$/;
      const patronFecha = /^\d{2}\/\d{2}\/\d{4}$/;

      if (this.nombre == "") {
        errores.push({ msg: "Proporcione un nombre" });
      }
      if (this.apellidoPaterno == "") {
        errores.push({ msg: "Proporcione un apellido paterno" });
      }
      if (this.apellidoMaterno == "") {
        errores.push({ msg: "Proporcione un apellido materno" });
      }
      if (this.telefono.toString() == "") {
        errores.push({ msg: "Proporcione un teléfono" });
      }

      if (this.correo == "") {
        errores.push({ msg: "Proporcione un correo" });
      }
      if (this.codigoPostal.toString() == "") {
        errores.push({ msg: "Proporcione un código postal" });
      }
      if (this.formatoFechaNacimiento == "") {
        errores.push({ msg: "Proporcione una fecha de nacimiento" });
      }
      if (this.sexo != "Hombre" && this.sexo != "Mujer" && this.sexo != "Otro") {
        errores.push({ msg: "Sexo inválido" });
      }

      if (
        isNaN(this.telefono.toString()) ||
        this.telefono.toString().length != 10
      ) {
        errores.push({ msg: "Teléfono inválido" });
      }

      if (!patronCorreo.test(this.correo)) {
        errores.push({ msg: "Correo inválido" });
      }

      if (!patronCodPostal.test(this.codigoPostal.toString())) {
        errores.push({ msg: "Código postal inválido" });
      }

      console.log(!patronFecha.test(this.formatoFechaNacimiento()));
      if (!patronFecha.test(this.fechaNacimiento)) {
        errores.push({ msg: "Fecha de nacimiento inválida" });
      }

      if (!patronFecha.test(this.fechaAltat)) {
        errores.push({ msg: "Fecha de alta inválida" });
      }

      console.log(this.fechaverfi);
      if (!patronFecha.test(this.fechaverfi)) {
        errores.push({ msg: "Fecha de verificación inválida" });
      }

      const sucursalEncontrada = this.listaSucursales.find((sucursal) => sucursal.division == this.division)
      if (!sucursalEncontrada) {
        errores.push({ msg: "División inválida" });
      }

      if (this.promotor == null) {
        errores.push({ msg: "Selecciona una opción para promotor" });
      }

      if (this.verificoemail == null) {
        errores.push({ msg: "Selecciona una opción para verificó email" });
      }

      if (!this.revisarMayoriaEdad(this.formatoFechaNacimiento())) {
        errores.push({ msg: "Para continuar necesitas ser mayor de edad" });
      }

      console.log(errores);
      if (errores.length > 0) {
        this.$swal({
          icon: "warning",
          type: "warning",
          title: "Oops...",
          text: errores[0].msg,
        });
        this.botonHabilitado = true;
      } else {
        this.procesarDatosValidados();
      }
    },
    async procesarDatosValidados() {
      console.log(this.usuarioActual);
      const objetoDatosUsuarioHasura = {
        nombre: this.nombre,
        apellidoP: this.apellidoPaterno,
        apellidoM: this.apellidoMaterno,
        Record_Id: this.tarjeta,
        telefono: this.telefono,
        email: this.correo,
        cp: this.codigoPostal,
        fechanacim: moment(this.fechaNacimiento, "DD/MM/YYYY").format("YYYY-MM-DD"),
        sexo: this.sexo,
        fechaalta: moment(this.fechaAltat, "DD/MM/YYYY").format("YYYY-MM-DD"),
        division: this.division,
        promotor: this.promotor.toString(),
        fechaverfi: moment(this.fechaverfi, "DD/MM/YYYY").format("YYYY-MM-DD"),
        verificoemail: this.verificoemail.toString(),
        dioalta: this.usuarioActual.nombre
      };

      let objetoDatosUsuarioFirebase = {...objetoDatosUsuarioHasura}
      objetoDatosUsuarioFirebase["fechaaltat"] = objetoDatosUsuarioHasura.fechaalta;
      objetoDatosUsuarioFirebase["fechaalta"] = null;
      objetoDatosUsuarioFirebase["fechaverif"] = objetoDatosUsuarioHasura.fechaverfi;
      objetoDatosUsuarioFirebase["fechaverfi"] = null;

      console.log(objetoDatosUsuarioFirebase);
      console.log(objetoDatosUsuarioHasura);
      try {
        await this.editarTarjetahabienteFirebaseManual(objetoDatosUsuarioFirebase);
        await this.editarTarjetahabienteHasuraManual(objetoDatosUsuarioHasura);

        this.$swal({
          icon: "success",
          type: "success",
          title: "Usuario editado exitosamente!",
          allowOutsideClick: false,
          confirmButtonText: "Continuar",
        })
      } catch (error) {
        console.log(error);
        this.$swal({
          icon: error.code ? "error" : "warning",
          type: error.code ? "error" : "warning",
          title: error.code ? error.code : "Oops...",
          text: error.msg,
        });
      } finally{
        this.botonHabilitado = true;
      }
    },
    // cancelarVerificacion() {
    //   this.resetearCampos();
    //   this.$router.go(-1);
    // },

    // resetearCampos() {
    //   this.nombre = "";
    //   this.telefono = "";
    //   this.correo = "";
    //   this.codigoPostal = "";
    //   this.fechaNacimiento = "";
    //   this.tarjeta = "";
    //   this.puntos = "";
    //   this.sexo = "";
    //   this.terminos = false;
    // },
    revisarMayoriaEdad(fecha) {
      const fechaActual = moment().format("YYYY-MM-DD");
      const fechaActualOffsetTimestamp =
        parseInt(moment(fechaActual).format("x")) + 39600000 + 3540000;
      const fechaRevisar = moment(fecha).format("x");
      console.log(fechaActualOffsetTimestamp);
      console.log(fechaRevisar);
      // const fechaActual = new Date().getTime();
      // const fechaRevisar = new Date(fecha).getTime();
      // console.log(fechaActual);
      // console.log(fechaRevisar);
      console.log(fechaActualOffsetTimestamp - fechaRevisar);
      return fechaActualOffsetTimestamp - fechaRevisar >= 568067623175;
    },
    formatoFechaNacimiento() {
      console.log(this.fechaNacimiento);
      const arrayFecha = this.fechaNacimiento.split("/");
      const fechaFormatoMoment =
        arrayFecha[2] + "-" + arrayFecha[1] + "-" + arrayFecha[0];
      // console.log(fechaFormatoMoment);
      // const fechaRecibida = moment(fechaFormatoMoment);
      // console.log(fechaRecibida);
      // const fechaOffsetDia = parseInt(fechaRecibida.format('x')) - 86400000;
      // console.log(fechaOffsetDia)
      // const fechaFormato = moment(fechaOffsetDia).format("YYYY-MM-DD");
      console.log(fechaFormatoMoment);
      // console.log(this.fechaNacimiento);
      // const fechaFormato = new Date(this.fechaNacimiento);
      // console.log(fechaFormato);
      // const dia = fechaFormato.getDate().toString().padStart(2, "0");
      // console.log(dia);
      // const mes = (fechaFormato.getMonth() + 1).toString().padStart(2, "0");
      // const año = fechaFormato.getFullYear();
      // console.log(`${año}-${mes}-${dia+1}`);
      // return `${año}-${mes}-${dia}`;
      return fechaFormatoMoment;
    },
    // cambiarCorreoDefecto() {
    //   if (!this.noCorreo) {
    //     this.correo = "appsk@tiendasgarces.com";
    //   } else {
    //     this.correo = "";
    //   }
    // },
    darAutoFormatoFecha(fechaInicial, nombreVariable) {
      let fechaFormato = fechaInicial;
      fechaFormato = fechaFormato.replace(/\D/g, "");

      if (fechaFormato.length > 2) {
        fechaFormato =
          fechaFormato.substring(0, 2) + "/" + fechaFormato.substring(2);
      }
      if (fechaFormato.length > 5) {
        fechaFormato =
          fechaFormato.substring(0, 5) + "/" + fechaFormato.substring(5, 9);
      }

      switch (nombreVariable) {
        case "fechaNacimiento":
          this.fechaNacimiento = fechaFormato;
          break;
        case "fechaAltat":
          this.fechaAltat = fechaFormato;
          break;
        case "fechaverfi":
          this.fechaverfi = fechaFormato;
          break;
      }
    },
  },
};
</script>
<template>
  <!-- Reporte edicion

permiso correo en crudo unico

Rol editor tarjetas club

recomendo preregistro idcli tarjeta saldo record id -->

  <!-- 
Record Id

apellidoM*

apellidoP*

nombre*

email*

fechanacim*

sexo*

tarjeta*

telefono*

saldoc*

fechaalta

dioalta

division

promotor

fechaverfi

verificoemail

cp*

id_cli

actcte

preregistro

recomendo-->

  <div class="container">
    <div class="page-title">
      <div class="row">
        <div class="col">
          <h3>Edición Tarjeta Club</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="container d-flex justify-content-center">
    <div class="card w-100 px-1" style="max-width: 900px">
      <form @submit.prevent="validarFormularioVerificacion()">
        <div class="card-body">
          <div class="row">
            <div class="col-lg col-md-12 mb-3">
              <label class="col-form-label pt-0" for="tarjeta"
                >Tarjeta club(Record ID)</label
              >
              <input
                disabled
                class="form-control"
                id="tarjeta"
                type="number"
                aria-describedby="tarjeta"
                placeholder="Introduce tu tarjeta club"
                v-model.trim="tarjeta"
              />
            </div>
            <div class="col-lg col-md-12 mb-3">
              <label class="col-form-label pt-0" for="id_cli">Id cliente</label>
              <input
                disabled
                class="form-control"
                id="id_cli"
                type="number"
                aria-describedby="id_cli"
                placeholder="Introduce un id_cli"
                v-model.trim="id_cli"
              />
            </div>
          </div>

          <div class="mb-3">
            <label class="col-form-label pt-0" for="nombre">Nombre</label>
            <input
              class="form-control"
              id="nombre"
              type="text"
              aria-describedby="nombre"
              placeholder="Introduce tu nombre"
              v-model.trim="nombre"
            />
          </div>
          <div class="">
            <div class="row">
              <div class="col-lg col-md-12 mb-3">
                <label class="col-form-label pt-0" for="apellidoPaterno"
                  >Apellido paterno</label
                >
                <input
                  class="form-control"
                  id="apellidoPaterno"
                  type="text"
                  aria-describedby="apellidoPaterno"
                  placeholder="Introduce tu apellido paterno"
                  v-model.trim="apellidoPaterno"
                />
              </div>
              <div class="col-lg col-md-12 mb-3">
                <label class="col-form-label pt-0" for="apellidoMaterno"
                  >Apellido materno</label
                >
                <input
                  class="form-control"
                  id="apellidoMaterno"
                  type="text"
                  aria-describedby="apellidoMaterno"
                  placeholder="Introduce tu apellido materno"
                  v-model.trim="apellidoMaterno"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg col-md-12 mb-3">
              <label class="col-form-label pt-0" for="telefono">Teléfono</label>
              <div class="row">
                <div
                  class="col-lg-1 col-2 d-flex align-items-center justify-content-lg-start justify-content-center"
                  style="min-height: 100%"
                >
                  <label class="col-form-label" for="prefijo">+52</label>
                </div>
                <div class="col-lg-11 col-10">
                  <input
                    class="form-control"
                    id="telefono"
                    type="number"
                    aria-describedby="telefono"
                    placeholder="Introduce tu teléfono"
                    v-model.trim="telefono"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label class="col-form-label pt-0" for="correo"
              >Correo electrónico</label
            >
            <input
              class="form-control"
              id="correo"
              type="text"
              aria-describedby="correo"
              placeholder="Introduce tu correo electrónico"
              v-model.trim="correo"
            />
          </div>

          <div class="row">
            <div class="col-lg col-md-12 mb-3">
              <label class="col-form-label pt-0" for="codigoPostal"
                >Código postal</label
              >
              <input
                class="form-control"
                id="codifoPostal"
                type="number"
                aria-describedby="codigoPostal"
                placeholder="Introduce tu código postal"
                v-model.trim="codigoPostal"
              />
            </div>
            <div class="col-lg col-md-12 mb-3">
              <label class="col-form-label pt-0" for="fechaNacimiento"
                >Fecha de nacimiento</label
              >
              <input
                class="form-control"
                id="fechaNacimiento"
                type="tel"
                aria-describedby="fechaNacimiento"
                placeholder="DD/MM/AAAA"
                v-model.trim="fechaNacimiento"
                @input="darAutoFormatoFecha(fechaNacimiento, 'fechaNacimiento')"
              />
              <!-- <datepicker
                  id="fechaNacimiento"
                  class="datetimepicker-input digits"
                  :enable-time-picker="false"
                  type="date"
                  locale="es"
                  select-text="Confirmar"
                  cancel-text="Cancelar"
                  format="dd/MM/yyyy"
                  v-model.trim="fechaNacimiento"
                /> -->
            </div>
          </div>
          <div class="mb-3">
            <label class="col-form-label pt-0" for="puntos">Puntos</label>
            <input
              disabled
              class="form-control"
              id="puntos"
              type="number"
              aria-describedby="puntos"
              placeholder="Introduce tus puntos"
              v-model.trim="puntos"
            />
          </div>
          <div class="row">
            <div class="col-lg col-md-12 mb-3">
              <label class="col-form-label pt-0" for="fechaAlta"
                >Fecha Alta
              </label>
              <input
                class="form-control"
                id="fechaAlta"
                type="tel"
                aria-describedby="fechaAlta"
                placeholder="Introduce la fecha de alta"
                v-model.trim="fechaAltat"
                @input="darAutoFormatoFecha(fechaAltat, 'fechaAltat')"
              />
            </div>
            <div class="col-lg col-md-12 mb-3">
              <label class="col-form-label pt-0" for="fechaverfi">
                Fecha verificación
              </label>
              <input
                class="form-control"
                id="fechaverfi"
                type="tel"
                aria-describedby="fechaverfi"
                placeholder="Introduce la fecha de verificación"
                v-model.trim="fechaverfi"
                @input="darAutoFormatoFecha(fechaverfi, 'fechaverfi')"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-lg col-md-12 mb-3">
              <label class="col-form-label pt-0" for="dioalta">
                Dio de alta
              </label>
              <input
                disabled
                class="form-control"
                id="dioalta"
                type="text"
                aria-describedby="dioalta"
                placeholder="Introduce quién dio de alta"
                v-model.trim="dioalta"
              />
            </div>
            <div class="col-lg col-md-12 mb-3">
              <label class="col-form-label pt-0" for="preregistro">
                Pre registro
              </label>
              <input
                disabled
                class="form-control"
                id="preregistro"
                type="text"
                aria-describedby="preregistro"
                placeholder="Introduce quiién realizó el pre registro"
                v-model.trim="preregistro"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-lg col-md-12 mb-3">
              <label class="col-form-label pt-0" for="recomendo">
                Recomendo
              </label>
              <input
                disabled
                class="form-control"
                id="recomendo"
                type="text"
                aria-describedby="recomendo"
                placeholder="Introduce quién recomendó el registro"
                v-model.trim="recomendo"
              />
            </div>
            <div class="col-lg col-md-12 mb-3">
              <label class="col-form-label pt-0" for="division">
                División
              </label>
              <select
                class="form-select digits"
                id="division"
                v-model="division"
              >
                <option
                  v-for="sucursal in listaSucursales"
                  :value="sucursal.division"
                >
                  {{ sucursal.division }} - {{ sucursal.nombre }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-lg col-md-12 mb-3">
              <label class="col-form-label pt-0" for="promotor">Promotor</label>
              <div class="m-t-15 custom-radio-ml">
                <div class="form-check radio radio-primary">
                  <input
                    class="form-check-input"
                    id="promotorTrue"
                    type="radio"
                    name="promotor"
                    :value="true"
                    v-model="promotor"
                  />
                  <label class="form-check-label" for="promotorTrue"
                    >True</label
                  >
                </div>
                <div class="form-check radio radio-primary">
                  <input
                    class="form-check-input"
                    id="promotorFalse"
                    type="radio"
                    name="promotor"
                    :value="false"
                    v-model="promotor"
                  />
                  <label class="form-check-label" for="promotorFalse"
                    >False</label
                  >
                </div>
              </div>
            </div>

            <div class="col-lg col-md-12 mb-3">
              <label class="col-form-label pt-0">Verificó email</label>
              <div class="m-t-15 custom-radio-ml">
                <div class="form-check radio radio-primary">
                  <input
                    class="form-check-input"
                    id="verificoemailTrue"
                    type="radio"
                    name="verificoemail"
                    :value="true"
                    v-model="verificoemail"
                  />
                  <label class="form-check-label" for="verificoemailTrue"
                    >True</label
                  >
                </div>
                <div class="form-check radio radio-primary">
                  <input
                    class="form-check-input"
                    id="verificoemailFalse"
                    type="radio"
                    name="verificoemail"
                    :value="false"
                    v-model="verificoemail"
                  />
                  <label class="form-check-label" for="verificoemailFalse"
                    >False</label
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg col-md-12 mb-3">
              <label class="col-form-label pt-0" for="sexo">Sexo</label>
              <div class="m-t-15 custom-radio-ml">
                <div class="form-check radio radio-primary">
                  <input
                    class="form-check-input"
                    id="masculino"
                    type="radio"
                    name="masculino"
                    value="Hombre"
                    v-model.trim="sexo"
                  />
                  <label class="form-check-label" for="masculino">Hombre</label>
                </div>
                <div class="form-check radio radio-primary">
                  <input
                    class="form-check-input"
                    id="femenino"
                    type="radio"
                    name="femenino"
                    value="Mujer"
                    v-model.trim="sexo"
                  />
                  <label class="form-check-label" for="femenino">Mujer</label>
                </div>
                <div class="form-check radio radio-primary">
                  <input
                    class="form-check-input"
                    id="sinEspecificar"
                    type="radio"
                    name="sinEspecificar"
                    value="Otro"
                    v-model.trim="sexo"
                  />
                  <label class="form-check-label" for="sinEspecificar"
                    >Sin especificar</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer text-end">
          <RouterLink
            class="btn btn-secondary me-2"
            type="button"
            to="/historico-tarjetas"
          >
            Volver
          </RouterLink>
          <button :disabled="!botonHabilitado" class="btn btn-primary" type="submit">Guardar</button>
        </div>
      </form>
    </div>
  </div>
</template>
<style>
.dp__input_wrap input {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
