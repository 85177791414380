<script>
/* eslint-disable */
import { arraySucursales } from "@/constants/sucursales";
import moment from "moment";
export default {
  props: ["urlSiguiente"],
  data() {
    return {
      nombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      telefono: "",
      correo: "",
      codigoPostal: "",
      fechaNacimiento: "",
      sexo: "",
      terminos: false,
      botonDesactivado: false,
      tipoSucursal: "",
      sucursal: "",
      listaTipoSucursales: ["Super Kompras", "Surtitienda", "Acropolis"],
      listaSucursalesSk: [
        ...arraySucursales.sucursalesSk,
        ...arraySucursales.sucursalesSkMicro,
      ],
      listaSucursalesSurtitienda: [
        ...arraySucursales.sucursalesSurtitienda,
      ],
      listaSucursalesAcropolis: [
        ...arraySucursales.sucursalesAcropolis,
      ],
      listaSucursales: [],
    };
  },
  methods: {
    validarFormularioVerificacion() {
      this.botonDesactivado = true;
      const errores = [];
      const patronCorreo =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      const patronCodPostal = /^\d{5}$/;
      const patronFecha = /^\d{2}\/\d{2}\/\d{4}$/;

      if (this.nombre == "") {
        errores.push({ msg: "Proporcione un nombre" });
      }
      if (this.apellidoPaterno == "") {
        errores.push({ msg: "Proporcione un apellido paterno" });
      }
      if (this.apellidoMaterno == "") {
        errores.push({ msg: "Proporcione un apellido materno" });
      }
      if (this.telefono == "") {
        errores.push({ msg: "Proporcione un teléfono" });
      }

      if (this.correo == "") {
        errores.push({ msg: "Proporcione un correo" });
      }
      if (this.codigoPostal == "") {
        errores.push({ msg: "Proporcione un código postal" });
      }
      if (this.formatoFechaNacimiento == "") {
        errores.push({ msg: "Proporcione una fecha de nacimiento" });
      }
      if (this.sexo == "") {
        errores.push({ msg: "Proporcione un sexo" });
      }

      if (
        isNaN(this.telefono.toString()) ||
        this.telefono.toString().length != 10
      ) {
        errores.push({ msg: "Teléfono inválido" });
      }

      if (!patronCorreo.test(this.correo)) {
        errores.push({ msg: "Correo inválido" });
      }

      if (!patronCodPostal.test(this.codigoPostal.toString())) {
        errores.push({ msg: "Código postal inválido" });
      }
      console.log("TESTTTT");
      if (!patronFecha.test(this.fechaNacimiento)) {
        errores.push({ msg: "Introduce una fecha valida" });
      }

      if (!this.revisarMayoriaEdad(this.formatoFechaNacimiento())) {
        errores.push({ msg: "Para continuar necesitas ser mayor de edad" });
      }

      if (this.terminos == false) {
        errores.push({
          msg: "Para continuar es necesario aceptar los términos y condiciones",
        });
      }

      if (this.sucursal == "") {
        errores.push({
          msg: "Selecciona una sucursal",
        });
      }

      console.log(errores);
      if (errores.length > 0) {
        this.$swal({
          icon: "error",
          type: "warning",
          title: "Oops...",
          text: errores[0].msg,
        });
        this.botonDesactivado = false;
      } else {
        this.botonDesactivado = false;

        // this.mostrarAlertaNoCorreo();
        this.procesarDatosValidados();
      }
    },
    procesarDatosValidados() {
      this.botonDesactivado = false;

      let template = 0;
      let templateFolio = 0;
      let nombreSender = "";
      let titulo = "";
      let correoSender = "";

      switch (this.tipoSucursal) {
        case "Super Kompras":
          template = 4934261;
          templateFolio = 5200641;
          nombreSender = "Super Kompras";
          titulo = "Código de verificación Super Kompras";
          break;
        case "Acropolis":
          template = 4975552;
          templateFolio = 5200661;
          nombreSender = "Acrópolis";
          titulo = "Código de verificación Acrópolis";
          break;
        case "Surtitienda":
          template = 4975555;
          templateFolio = 5200641;
          nombreSender = "Surtitienda";
          titulo = "Código de verificación Surtitienda";
          break;
      }
      correoSender = "appsk@tiendasgarces.com";

      const objetoDatosUsuario = {
        nombre: this.nombre,
        apellidoPaterno: this.apellidoPaterno,
        apellidoMaterno: this.apellidoMaterno,
        telefono: this.telefono.toString(),
        correo: this.correo,
        codigoPostal: this.codigoPostal.toString(),
        fechaNacimiento: this.formatoFechaNacimiento(),
        sexo: this.sexo,
        correoSender: correoSender,
        nombreSender: nombreSender,
        template: template,
        templateFolio: templateFolio,
        titulo: titulo,
        sucursal: this.sucursal,
        preregistro: true,
      };
      localStorage.setItem("datosUsuario", JSON.stringify(objetoDatosUsuario));
      console.log(objetoDatosUsuario);

      this.resetearCampos();

      this.$router.push(this.urlSiguiente);
    },
    cancelarVerificacion() {
      this.resetearCampos();
    },

    resetearCampos() {
      this.nombre = "";
      this.telefono = "";
      this.correo = "";
      this.codigoPostal = "";
      this.fechaNacimiento = "";
      this.sexo = "";
      this.terminos = false;
    },
    revisarMayoriaEdad(fecha) {
      const fechaActual = moment().format("YYYY-MM-DD");
      const fechaActualOffsetTimestamp =
        parseInt(moment(fechaActual).format("x")) + 39600000 + 3540000;
      const fechaRevisar = moment(fecha).format("x");
      console.log(fechaActualOffsetTimestamp);
      console.log(fechaRevisar);
      // const fechaActual = new Date().getTime();
      // const fechaRevisar = new Date(fecha).getTime();
      // console.log(fechaActual);
      // console.log(fechaRevisar);
      console.log(fechaActualOffsetTimestamp - fechaRevisar);
      return fechaActualOffsetTimestamp - fechaRevisar >= 568067623175;
    },
    formatoFechaNacimiento() {
      console.log(this.fechaNacimiento);
      const arrayFecha = this.fechaNacimiento.split("/");
      const fechaFormatoMoment =
        arrayFecha[2] + "-" + arrayFecha[1] + "-" + arrayFecha[0];
      // console.log(fechaFormatoMoment);
      // const fechaRecibida = moment(fechaFormatoMoment);
      // console.log(fechaRecibida);
      // const fechaOffsetDia = parseInt(fechaRecibida.format('x')) - 86400000;
      // console.log(fechaOffsetDia)
      // const fechaFormato = moment(fechaOffsetDia).format("YYYY-MM-DD");
      console.log(fechaFormatoMoment);
      // console.log(this.fechaNacimiento);
      // const fechaFormato = new Date(this.fechaNacimiento);
      // console.log(fechaFormato);
      // const dia = fechaFormato.getDate().toString().padStart(2, "0");
      // console.log(dia);
      // const mes = (fechaFormato.getMonth() + 1).toString().padStart(2, "0");
      // const año = fechaFormato.getFullYear();
      // console.log(`${año}-${mes}-${dia+1}`);
      // return `${año}-${mes}-${dia}`;
      return fechaFormatoMoment;
    },
    darAutoFormatoFecha() {
      let fechaFormato = this.fechaNacimiento.replace(/\D/g, ""); // Elimina todos los caracteres no numéricos

      if (fechaFormato.length > 2) {
        fechaFormato =
          fechaFormato.substring(0, 2) + "/" + fechaFormato.substring(2);
      }
      if (fechaFormato.length > 5) {
        fechaFormato =
          fechaFormato.substring(0, 5) + "/" + fechaFormato.substring(5, 9);
      }

      this.fechaNacimiento = fechaFormato;
    },
    llenarListadoSucursales() {},
  },
  watch: {
    tipoSucursal(nuevoTipoSucursal) {
      switch (nuevoTipoSucursal) {
        case "Super Kompras":
          this.listaSucursales = this.listaSucursalesSk;
          break;
        case "Surtitienda":
          this.listaSucursales = this.listaSucursalesSurtitienda;
          break;
        case "Acropolis":
          this.listaSucursales = this.listaSucursalesAcropolis;
          break;
      }
      this.sucursal = "";
    },
  },
};
</script>
<template>
  <div class="container">
    <div class="page-title">
      <div class="row">
        <div class="col">
          <h3>Pre registro</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="container d-flex justify-content-center">
    <div class="card w-100 px-1" style="max-width: 900px">
      <form @submit.prevent="validarFormularioVerificacion()">
        <div class="card-body">
          <div class="mb-3">
            <label class="col-form-label pt-0" for="nombre">Nombre</label>
            <input
              class="form-control"
              id="nombre"
              type="text"
              aria-describedby="nombre"
              placeholder="Introduce tu nombre"
              v-model.trim="nombre"
            />
          </div>
          <div class="">
            <div class="row">
              <div class="col-lg col-md-12 mb-3">
                <label class="col-form-label pt-0" for="apellidoPaterno"
                  >Apellido paterno</label
                >
                <input
                  class="form-control"
                  id="apellidoPaterno"
                  type="text"
                  aria-describedby="apellidoPaterno"
                  placeholder="Introduce tu apellido paterno"
                  v-model.trim="apellidoPaterno"
                />
              </div>
              <div class="col-lg col-md-12 mb-3">
                <label class="col-form-label pt-0" for="apellidoMaterno"
                  >Apellido materno</label
                >
                <input
                  class="form-control"
                  id="apellidoMaterno"
                  type="text"
                  aria-describedby="apellidoMaterno"
                  placeholder="Introduce tu apellido materno"
                  v-model.trim="apellidoMaterno"
                />
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label class="col-form-label pt-0" for="telefono">Teléfono</label>
            <div class="row">
              <div
                class="col-lg-1 col-2 d-flex align-items-center justify-content-lg-start justify-content-center"
                style="min-height: 100%"
              >
                <label class="col-form-label" for="prefijo">+52</label>
              </div>
              <div class="col-lg-11 col-10">
                <input
                  class="form-control"
                  id="telefono"
                  type="number"
                  aria-describedby="telefono"
                  placeholder="Introduce tu teléfono"
                  v-model.trim="telefono"
                />
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label class="col-form-label pt-0" for="correo"
              >Correo electrónico</label
            >
            <input
              class="form-control"
              id="correo"
              type="text"
              aria-describedby="correo"
              placeholder="Introduce tu correo electrónico"
              v-model.trim="correo"
            />
          </div>
          <div class="">
            <div class="row">
              <div class="col-lg col-md-12 mb-3">
                <label class="col-form-label pt-0" for="codifoPostal"
                  >Código postal</label
                >
                <input
                  class="form-control"
                  id="codifoPostal"
                  type="number"
                  aria-describedby="codigoPostal"
                  placeholder="Introduce tu código postal"
                  v-model.trim="codigoPostal"
                />
              </div>
              <div class="col-lg col-md-12 mb-3">
                <label class="col-form-label pt-0" for="fechaNacimiento"
                  >Fecha de nacimiento</label
                >
                <!-- <datepicker
                  id="fechaNacimiento"
                  class="datetimepicker-input digits"
                  :enable-time-picker="false"
                  type="date"
                  locale="es"
                  select-text="Confirmar"
                  cancel-text="Cancelar"
                  format="dd/MM/yyyy"
                  v-model.trim="fechaNacimiento"
                /> -->
                <input
                  class="form-control"
                  id="fechaNacimiento"
                  type="tel"
                  aria-describedby="fechaNacimiento"
                  placeholder="DD/MM/AAAA"
                  v-model.trim="fechaNacimiento"
                  @input="darAutoFormatoFecha()"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg col-md-12 mb-3">
              <label class="form-label" for="exampleFormControlSelect30"
                >Tipo de sucursal</label
              >
              <select
                class="form-select digits"
                id="exampleFormControlSelect30"
                @input="llenarListadoSucursales()"
                v-model="tipoSucursal"
              >
                <option v-for="tipo in listaTipoSucursales">{{ tipo }}</option>
              </select>
            </div>
            <div class="col-lg col-md-12 mb-3">
              <label class="form-label" for="exampleFormControlSelect30"
                >Sucursal</label
              >
              <select
                class="form-select digits"
                id="exampleFormControlSelect30"
                v-model="sucursal"
              >
                <option
                  v-for="sucursal in listaSucursales"
                  :value="sucursal.division"
                >
                  {{ sucursal.nombre }}
                </option>
              </select>
            </div>
          </div>
          <div class="">
            <div class="row">
              <div class="col-lg col-md-12 mb-3">
                <label class="col-form-label pt-0" for="sexo">Sexo</label>
                <div class="m-t-15 custom-radio-ml">
                  <div class="form-check radio radio-primary">
                    <input
                      class="form-check-input"
                      id="masculino"
                      type="radio"
                      name="masculino"
                      value="Hombre"
                      v-model.trim="sexo"
                    />
                    <label class="form-check-label" for="masculino"
                      >Hombre</label
                    >
                  </div>
                  <div class="form-check radio radio-primary">
                    <input
                      class="form-check-input"
                      id="femenino"
                      type="radio"
                      name="femenino"
                      value="Mujer"
                      v-model.trim="sexo"
                    />
                    <label class="form-check-label" for="femenino">Mujer</label>
                  </div>
                  <div class="form-check radio radio-primary">
                    <input
                      class="form-check-input"
                      id="sinEspecificar"
                      type="radio"
                      name="sinEspecificar"
                      value="Otro"
                      v-model.trim="sexo"
                    />
                    <label class="form-check-label" for="sinEspecificar"
                      >Sin especificar</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-lg col-md-12 d-flex align-items-center mb-3">
                <div class="checkbox checkbox-dark m-square">
                  <div class="d-flex justify-content-start ms-1">
                    <input
                      id="terminos"
                      type="checkbox"
                      v-model.trim="terminos"
                    />
                    <label class="mt-0" for="terminos"
                      >Acepto los términos y condiciones</label
                    >
                  </div>
                  <h6 class="f-w-600">
                    <a
                      target="_blank"
                      href="https://www.superkompras.com.mx/aviso-privacidad/"
                      >Consulta nuestro aviso de privacidad</a
                    >
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer text-end">
          <button
            class="btn btn-secondary me-2"
            type="button"
            @click="cancelarVerificacion()"
          >
            Cancelar
          </button>
          <button class="btn btn-primary" type="submit">Continuar</button>
        </div>
      </form>
    </div>
  </div>
</template>
<style>
.dp__input_wrap input {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
