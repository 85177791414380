<script>
import moment from "moment";
import BasicTable from "../../../components/basicTable.vue";
import FormularioDetallePedido from "../../../components/formularioDetallePedido.vue";
import { PDFDocument, rgb } from "pdf-lib";
export default {
  data() {
    return {
      pedido: {},
      infoPedido: [],
      infoCliente: {},
      infoPago: {},
      productos: [],
      headersInfoPedido: [
        "Tipo de envio",
        "Fecha y hora",
        "Sucursal",
        "Estatus",
        "Key BBDD"
      ],
      camposInfoPedido: [
        { tipo: "texto", clave: "tipoEnvio", bold: true },
        { tipo: "texto", clave: "fechaHora" },
        { tipo: "texto", clave: "sucursal" },
        { tipo: "estatus", clave: "estatus" },
        { tipo: "texto", clave: "keyBBDD" },
      ],
      listaEstatusInfoPedido: [
        { estatus: "Solicitud", color: "#F97316" },
        { estatus: "En proceso", color: "#3B82F6" },
        { estatus: "Cancelado", color: "#E11D48" },
        { estatus: "Concluido", color: "#10B981" },
        { estatus: "En ruta", color: "#A21CAF" },
      ],
      headersArticulos: [
        "ID",
        "Imagen",
        "Nombre",
        "Cantidad Piezas o Gramaje",
        "Precio",
        "Comentarios",
        "Acciones",
      ],
      camposArticulos: [
        { tipo: "texto", clave: "id" },
        { tipo: "imagen", clave: "imagen" },
        { tipo: "texto", clave: "nombre" },
        { tipo: "texto", clave: "cantidad" },
        { tipo: "texto", clave: "precio" },
        { tipo: "texto", clave: "comentarios" },
        { tipo: "boton" },
      ],
    };
  },
  components: {
    BasicTable,
    FormularioDetallePedido,
  },
  created() {
    this.cargarPedido();
  },
  methods: {
    cargarPedido() {
      this.pedido = JSON.parse(localStorage.getItem("detallePedido"));

      const fechaSegundos = this.pedido["TimeSolicitud"] / 1000;
      const fechaFormato = moment
        .unix(fechaSegundos)
        .format("DD/MM/YYYY, h:mm A");

      this.infoPedido = [
        {
          tipoEnvio: this.pedido["recogerTienda"]
            ? "Recoger en tienda"
            : "Envío a domicilio",
          fechaHora: fechaFormato,
          sucursal: this.pedido["sucursal"],
          estatus: this.pedido["Status"],
          keyBBDD: this.pedido["id"],
        },
      ];

      this.infoCliente = {
        nombre: this.pedido["Cliente name"],
        clienteId: this.pedido["Cliente id"],
        telefono: this.pedido["Cliente telefono"],
        direccion: this.pedido["Direccion"],
        codigoSk: this.pedido["TarjetaClubCodigo"],
        comentarios: this.pedido["Comentarios"],
        fotografia: this.pedido?.TarjetaClub.fileName,
      };

      this.productos = [];
      if (this.pedido.Productos) {
        for (const [id, producto] of Object.entries(this.pedido.Productos)) {
          const precioRedondeado = Math.round(producto.Total * 100) / 100;

          const objetoProducto = {
            id: id,
            imagen: producto.Imagen,
            nombre: producto.Nombre,
            cantidad: producto.Cantidad,
            precio: `$${precioRedondeado}`,
            comentarios: producto.Comentarios ? producto.Comentarios : "-",
          };
          this.productos.push(objetoProducto);
        }
      }

      this.infoPago = {
        subtotal: this.pedido.Subtotal ? (Math.round(this.pedido.Subtotal * 100) / 100) : 0,
        envio: this.pedido.Envio ? (Math.round(this.pedido.Envio * 100) / 100) : 0,
        total: this.pedido.Total ? (Math.round(this.pedido.Total * 100) / 100) : 0,
      }
    },
    // Función para dividir texto en varias líneas basándose en el número de caracteres
    dividirTextoEnLineas(texto, maxCharsPorLinea) {
      const lineas = [];
      let inicio = 0;

      while (inicio < texto.length) {
        lineas.push(texto.slice(inicio, inicio + maxCharsPorLinea));
        inicio += maxCharsPorLinea;
      }

      return lineas;
    },
    // Método para generar y descargar el PDF
    async generarPDF() {
      const pdfDoc = await PDFDocument.create();
      let page = pdfDoc.addPage([600, 800]); // Añadir una página con tamaño específico
      const { height } = page.getSize();

      const maxCharsPorLinea = 65; // Número máximo de caracteres por línea

      page.drawText(`Folio: ${this.pedido.Folio}`, { x: 50, y: height - 50, size: 20, color: rgb(0, 0.53, 0.71) });
      page.drawText(`Cliente: ${this.infoCliente.nombre}`, { x: 50, y: height - 100, size: 14 });
      page.drawText(`Teléfono: ${this.infoCliente.telefono}`, { x: 50, y: height - 140, size: 14 });

      let posicionY = height - 160;
      page.drawText(``, { x: 50, y: posicionY, size: 14 });
      posicionY -= 20;

      // Dividir el texto largo en líneas según el número máximo de caracteres
      const lineasDireccion = this.dividirTextoEnLineas(`Dirección: ${this.infoCliente.direccion}`, maxCharsPorLinea);

      lineasDireccion.forEach((linea) => {
        if (posicionY < 50) {
          page = pdfDoc.addPage([600, 800]); // Añadir nueva página si es necesario
          posicionY = height - 50;
        }
        page.drawText(linea, { x: 50, y: posicionY, size: 14 });
        posicionY -= 20; // Ajustar la posición Y para la siguiente línea
      });

      let posicionTablaY = posicionY - 30;

      page.drawText('Codigo', { x: 50, y: posicionTablaY, size: 12, color: rgb(0, 0, 0) });
      page.drawText('Nombre', { x: 170, y: posicionTablaY, size: 12, color: rgb(0, 0, 0) });
      page.drawText('Cantidad', { x: 440, y: posicionTablaY, size: 12, color: rgb(0, 0, 0) });
      page.drawText('Precio', { x: 500, y: posicionTablaY, size: 12, color: rgb(0, 0, 0) });

      page.drawLine({
        start: { x: 50, y: posicionTablaY - 10 },
        end: { x: 550, y: posicionTablaY - 10 },
        thickness: 1, // Grosor de la línea
        color: rgb(0, 0, 0)
      });

      this.productos.forEach((producto) => {
        posicionTablaY -= 30;

        if (posicionTablaY < 50) {
          page = pdfDoc.addPage([600, 800]);
          posicionTablaY = height - 50;
        }

        page.drawText(`${producto.id}`, { x: 50, y: posicionTablaY, size: 10 });
        page.drawText(`${producto.nombre}`, { x: 170, y: posicionTablaY, size: 10 });
        page.drawText(`${producto.cantidad}`, { x: 440, y: posicionTablaY, size: 10 });
        page.drawText(`${producto.precio}`, { x: 500, y: posicionTablaY, size: 10 });
      });

      page.drawText(`Subtotal: $${this.infoPago.subtotal}`, { x: 50, y: posicionTablaY - 40, size: 14 });
      page.drawText(`Envío: $${this.infoPago.envio}`, { x: 50, y: posicionTablaY - 60, size: 14 });
      page.drawText(`Total: $${this.infoPago.total}`, { x: 50, y: posicionTablaY - 80, size: 14 });

      // Guardar el PDF
      const pdfBytes = await pdfDoc.save();

      // Crear un enlace para descargar el PDF
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `detalle_${this.pedido.Folio}.pdf`;
      link.click();
    }
  },
};
</script>

<template>
  <Breadcrumbs :main="'Pedidos'" :title="pedido.Folio" />
  <BasicTable :headers="headersInfoPedido" :data="infoPedido" :campos="camposInfoPedido"
    :listaEstatus="listaEstatusInfoPedido" @verDetalleElemento="verDetallePedido"></BasicTable>
  <FormularioDetallePedido :infoCliente="infoCliente"></FormularioDetallePedido>
  <BasicTable :headers="headersArticulos" :data="productos" :campos="camposArticulos" title="Artículos"
    @verDetalleElemento="verDetallePedido" total="true" titleAmount="artículos"></BasicTable>
  <div class="card">
    <div class="card-header pb-0">
      <h4>Detalle de pago</h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-4">
          <div class="row mb-2">
            <div class="col-6">
              <h6>Subtotal</h6>
            </div>
            <div class="col-6">${{ infoPago.subtotal }}</div>
          </div>
          <div class="row mb-2">
            <div class="col-6">
              <h6>Envio</h6>
            </div>
            <div class="col-6">${{ infoPago.envio }}</div>
          </div>
          <div class="row mb-2">
            <div class="col-6">
              <h6>Total</h6>
            </div>
            <div class="col-6">${{ infoPago.total }}</div>
          </div>
        </div>
        <div class="col-8">
          <span class="fw-bold pe-4">Método de pago</span>
          <span class="d-inline-block text-capitalize">
            <div class="d-flex align-items-center">
              {{ pedido.Pago }}
              <i v-if="pedido.Pago == 'efectivo'" class="ms-3 icofont icofont-2x icofont-bill"
                style="color: #13cc13;"></i>
              <i v-if="pedido.Pago == 'terminal'" class="ms-3 icofont icofont-2x icofont-bank-transfer-alt"
                style="color: grey;"></i>
              <i v-if="pedido.Pago == 'visa'" class="ms-3 icofont icofont-2x icofont-visa" style="color: #4c31e6;"></i>
              <i v-if="pedido.Pago == 'mastercard'" class="ms-3 icofont icofont-2x icofont-mastercard"
                style="color: #e42626;"></i>

            </div>
          </span>
        </div>
        <div class="row mb-2">
          <div class="col-6">
            <br>
            <button class="btn btn-primary px-2 py-2 rounded" style="
                background-color: #900C3F !important;
                border-color: #900C3F !important;
                color: #fff;
              " @click="generarPDF"> Descargar Pdf
           <i class="icofont icofont-download ms-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
