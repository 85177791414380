<script>
import FormularioEdicionTarjeta from '../../components/formularioEdicionManualTarjeta.vue';

export default{
    data(){
        return{
            idTarjeta: ""
        }
    },
    components:{
        FormularioEdicionTarjeta
    },
    created(){
        this.idTarjeta = this.$route.params.id;
    }
}
</script>
<template>
    <FormularioEdicionTarjeta :idTarjeta="idTarjeta"/>
</template>