import { createRouter, createWebHistory } from "vue-router";
import LoginAuth from "../pages/admin/loginAuth.vue";
import RecuperarContraseña from "../pages/admin/recuperarContraseña.vue";
import BuscarTarjeta from "../pages/admin/buscarTarjeta.vue";
import VerificarTarjeta from "../pages/admin/verificarTarjeta.vue";
import VerificarTelefono from "../pages/admin/verificarTelefono.vue";
import VerificarCorreo from "../pages/admin/verificarCorreo.vue";
import DarAltaTarjeta from "../pages/admin/darAltaTarjeta.vue";
import Preregistro from "../pages/admin/preregistroTarjetahabientes.vue";
import AltaTarjetaRegalo from "../pages/admin/altaTarjetaRegalo.vue";
import ConsultaUsuarios from "../pages/admin/consultaUsuarios.vue";
import AltaUsuarios from "../pages/admin/altaUsuarios.vue";
import HistoricoTarjetasVerificadas from "../pages/admin/historicoTarjetasVerificadas.vue";
import GraficasVerificaciones from "../pages/admin/graficasVerificaciones.vue";
import PedidosFiltroview from "@/pages/admin/PedidosFiltroview.vue";
// import VerificarEmpleado from '../pages/admin/verificarEmpleado.vue'
import AgregarCampaña from "@/pages/admin/configuracion/agregarCampaña.vue";

import BuscarTarjetaCliente from "../pages/cliente/buscarTarjetaCliente.vue";
import VerificarTarjetaCliente from "../pages/cliente/verificarTarjetaCliente.vue";
import VerificarTelefonoCliente from "../pages/cliente/verificarTelefonoCliente.vue";
import VerificarCorreoCliente from "../pages/cliente/verificarCorreoCliente.vue";
import PreregistroCliente from "../pages/cliente/preregistroCliente.vue";
import FolioCliente from "../pages/cliente/folioCliente.vue";
import ActivacionTarjeta from "../pages/cliente/activacionTarjeta.vue";
import SeccionesComercio from '../pages/admin/comercio/seccionesComercio.vue'
import DetalleSeccion from '../pages/admin/comercio/detalleSeccion.vue'
import AgregarProductos from '../pages/admin/comercio/agregarProductos.vue'
import PedidosComercio from '../pages/admin/comercio/pedidosComercio.vue'
import DetallePedido from '../pages/admin/comercio/detallePedido.vue'
import GenerarQrs from '../pages/admin/qrs/generarQrs.vue'
import ConsultarFoliosQrs from '../pages/admin/qrs/consultarFoliosQrs.vue'

import ErrorPage5 from '../pages/error/errorPage5.vue'
import ErrorPage1 from '../pages/error/errorPage1.vue'

import BodyView from "@/layout/BodyView.vue";
import BodyViewCliente from "@/layout/BodyViewCliente.vue";
import store from "../store";
import myApp from '@/main';
import EdicionManualTarjeta from "@/pages/admin/edicionManualTarjeta.vue";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [    
    {
      path: "/login",
      name: "login",
      component: LoginAuth,
    },
    {
      path: "/pagina-no-encontrada",
      name: "pagina-no-encontrada",
      component: ErrorPage5,
    },
    {
      path: "/pagina-sin-permisos",
      name: "pagina-sin-permisos",
      component: ErrorPage1,
    },
    //RUTAS ADMIN
    {
      path: "/buscar",
      component: BodyView,
      children: [
        {
          path: "/buscar",
          name: "buscar",
          component: BuscarTarjeta,
        },
      ],
      meta: { authRequired: true },
    },
    {
      path: "/verificar",
      component: BodyView,
      children: [
        {
          path: "/verificar",
          name: "verificar",
          component: VerificarTarjeta,
        },
      ],
      meta: { authRequired: true },
    },
    {
      path: "/verificar-telefono",
      component: BodyView,
      children: [
        {
          path: "/verificar-telefono",
          name: "verificar-telefono",
          component: VerificarTelefono,
        },
      ],
      meta: { authRequired: true },
    },
    {
      path: "/verificar-correo",
      component: BodyView,
      children: [
        {
          path: "/verificar-correo",
          name: "verificar-correo",
          component: VerificarCorreo,
        },
      ],
      meta: { authRequired: true },
    },

    {
      path: "/alta-tarjeta",
      component: BodyView,
      children: [
        {
          path: "/alta-tarjeta",
          name: "dar-alta-tarjeta",
          component: DarAltaTarjeta,
        },
      ],
      meta:{authRequired: true}
      
    },    
    {
      path: '/secciones',
      component: BodyView,
      children:[
        {
          path: '/secciones',
          name: 'secciones',
          component: SeccionesComercio,
        }
      ],
      meta:{authRequired: true, rolApp:true}      
    },
    {
      path: '/detalles-seccion',
      component: BodyView,
      children:[
        {
          path: '/detalles-seccion/:id',
          name: 'detalles-seccion',
          component: DetalleSeccion,
        }
      ],
      meta:{authRequired: true, rolApp:true}      
    },
    {
      path: '/agregar-productos',
      component: BodyView,
      children:[
        {
          path: '/agregar-productos/:id/:nombreSeccion',
          name: 'agregar-productos',
          component: AgregarProductos,
          
        },
        
      ],
      meta:{authRequired: true, rolApp:true}      
    },
    {
      path: '/pedidos',
      component: BodyView,
      children:[
        {
          path: '/pedidos',
          name: 'pedidos',
          component: PedidosComercio,
        }
      ],
      meta:{authRequired: true, rolApp:true}      
    },
    {
      path: '/detalle-pedido',
      component: BodyView,
      children:[
        {
          path: '/detalle-pedido/:id',
          name: 'detalle-pedido',
          component: DetallePedido,
        }
      ],
      meta:{authRequired: true, rolApp:true}      
    },
    {
      path: '/pedidos-filtro',
      component: BodyView,
      children:[
        {
          path: '/pedidos-filtro',
          name: 'pedidos-filtro',
          component: PedidosFiltroview,
        }
      ],
      meta:{authRequired: true, rolApp:true}      
    },
    {
      path: "/preregistro",
      component: BodyView,
      children: [
        {
          path: "/preregistro",
          name: "preregistro",
          component: Preregistro,
        },
      ],
      meta: { authRequired: true },
    },
    {
      path: "/tarjeta-regalo",
      component: BodyView,
      children: [
        {
          path: "/tarjeta-regalo",
          name: "tarjeta-regalo",
          component: AltaTarjetaRegalo,
        },
      ],
      meta: { authRequired: true },
    },
    {
      path: "/consulta-usuarios",
      component: BodyView,
      children: [
        {
          path: "/consulta-usuarios",
          name: "consulta-usuarios",
          component: ConsultaUsuarios,
        },
      ],
      meta: { authRequired: true, admin: true },
    },
    {
      path: "/alta-usuarios",
      component: BodyView,
      children: [
        {
          path: "/alta-usuarios",
          name: "alta-usuarios",
          component: AltaUsuarios,
        },
      ],
      meta: { authRequired: true, admin: true },
    },
    {
      path: "/historico-tarjetas",
      component: BodyView,
      children: [
        {
          path: "/historico-tarjetas",
          name: "historico-tarjetas",
          component: HistoricoTarjetasVerificadas,
        },
      ],
      meta: { authRequired: true, admin: true },
    },
    {
      path: "/graficas-verificaciones",
      component: BodyView,
      children: [
        {
          path: "/graficas-verificaciones",
          name: "graficas-verificaciones",
          component: GraficasVerificaciones,
        },
      ],
      meta: { authRequired: true, admin: true },
    },
    {
      path: "/recuperar-contrasena",
      name: "recuperar-contraseña",
      component: RecuperarContraseña,
    },
    //QR's 
    
    {
      path: "/generar-qrs",
      component: BodyView,
      children: [
        {
          path: "/generar-qrs",
          name: "generar-qrs",
          component: GenerarQrs,
        },
      ],
      meta: { authRequired: true, rolApp:true },
    },
    {
      path: "/consultar-folios-qrs",
      component: BodyView,
      children: [
        {
          path: "/consultar-folios-qrs",
          name: "consultar-folios-qrs",
          component: ConsultarFoliosQrs,
        },
      ],
      meta: { authRequired: true, rolApp:true },
    },
    {
      path: "/edicion-tarjetas-club",
      component: BodyView,
      children: [
        {
          path: "/edicion-tarjetas-club/:id",
          name: "edicion-tarjetas-club",
          component: EdicionManualTarjeta,
        },
      ],
      meta: { authRequired: true, editorTarjetas:true },
    },
    //ruta pagina campañas
    {
      path: "/agregar-campana",
      component: BodyView,
      children: [
        {
          path: "/agregar-campana",
          name: "agregar-campaña",
          component: AgregarCampaña,
        },
      ],
      meta: { authRequired: true, rolApp: true },
    },
    //**************************************************RUTAS CLIENTE*********************************************
    {
      path: "/cliente/buscar",
      component: BodyViewCliente,
      children: [
        {
          path: "/cliente/buscar",
          name: "buscar-cliente",
          component: BuscarTarjetaCliente,
        },
      ],
    },
    {
      path: "/cliente/verificar",
      component: BodyViewCliente,
      children: [
        {
          path: "/cliente/verificar",
          name: "verificar-cliente",
          component: VerificarTarjetaCliente,
        },
      ],
    },
    {
      path: "/cliente/verificar-telefono",
      component: BodyViewCliente,
      children: [
        {
          path: "/cliente/verificar-telefono",
          name: "verificar-telefono-cliente",
          component: VerificarTelefonoCliente,
        },
      ],
    },
    {
      path: "/cliente/verificar-correo",
      component: BodyViewCliente,
      children: [
        {
          path: "/cliente/verificar-correo",
          name: "verificar-correo-cliente",
          component: VerificarCorreoCliente,
        },
      ],
    },
    {
      path: "/cliente/preregistro",
      component: BodyViewCliente,
      children: [
        {
          path: "/cliente/preregistro",
          name: "preregistro-cliente",
          component: PreregistroCliente,
        },
      ],
    },
    {
      path: "/cliente/folio",
      component: BodyViewCliente,
      children: [
        {
          path: "/cliente/folio",
          name: "folio-cliente",
          component: FolioCliente,
        },
      ],
    },
    {
      path: "/cliente/activacion-tarjeta/:numTarjeta",
      component: BodyViewCliente,
      children: [
        {
          path: "/cliente/activacion-tarjeta/:numTarjeta",
          name: "activacion-tarjeta",
          component: ActivacionTarjeta,
        },
      ],
    },
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: "/cliente",
      redirect: "/cliente/buscar",
    },
    { path: '/:pathMatch(.*)*', component:ErrorPage5 },
  ],
});

router.beforeEach(async (to, from, next) => {
  console.log(to.meta.authRequired);
  if (to.meta.authRequired) {
    const usuario = await store.dispatch("autenticacion/currentUser");

    if (usuario) {
      await store.dispatch(
        "autenticacion/buscarUsuarioAuntenticado",
        usuario.uid
      );

      const usuarioActual = store.getters["autenticacion/getUsuarioActual"];
      console.log(usuarioActual);

      if (usuarioActual) {
        if (to.meta.admin) {
          if (usuarioActual.rol == "gerente" || usuarioActual.rol == "editorTarjetas" || usuarioActual.rol == "supervisor") {
            next();
          } else {
            next("/pagina-sin-permisos");
          }
        } else if(to.meta.rolApp){
          if(usuarioActual.rolApp){
            next();
          }else{
            next("/pagina-sin-permisos");
          }
        } else if(to.meta.editorTarjetas){
          if(usuarioActual.rol == "editorTarjetas"){
            next();
          }else{
            next("/pagina-sin-permisos");
          }
        }else {
          next();
        }
      }

      // next();
    } else {
      next("/pagina-sin-permisos");
    }
  } else if (to.path == "/login") {
    console.log("Login");
    const usuario = await store.dispatch("autenticacion/currentUser");
    console.log(usuario);
    if (usuario) {
      await store.dispatch(
        "autenticacion/buscarUsuarioAuntenticado",
        usuario.uid
      );

      

      const usuarioActual = store.getters["autenticacion/getUsuarioActual"];
      console.log(usuarioActual);

      if (usuarioActual) {
        next("/buscar");
      } else {
        next();
      }
    }else{
      next();
    }
  } else {
    next();
  }
});



export default router;
