<script>
/* eslint-disable */
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  props: ["urlSiguiente", "nombreEditable","recomendado"],
  data() {
    return {
      nombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      telefono: "",
      correo: "",
      codigoPostal: "",
      fechaNacimiento: "",
      tarjeta: "",
      puntos: "",
      sexo: "",
      terminos: false,
      correoSender: "",
      nombreSender: "",
      template: "",
      titulo: "",
      verificado: false,
      fechaVerificacion: "",
      botonDesactivado: false,
      cliente: false,
      idCli: "",
      fechaAltat: "",
      listaEmpleados: [],
      recomendo: "SIN RECOMENDACIÓN",
    };
  },
  created() {
    this.cargarDatosTarjeta();
    this.verificarVigenciaListadoEmpleados();
  },
  computed: {
    ...mapGetters("autenticacion", ["getUsuarioActual"]),
  },
  methods: {
    ...mapActions("validacion", [
      "buscarTarjetahabienteFirebase",
      "consultarEmpleados",
    ]),
    verificarVigenciaListadoEmpleados() {
      const tiempoVigencia = 24 * 60 * 60 * 1000;
      const tiempoActual = moment().format("x");
      let listaEmpleadosJson = localStorage.getItem("listaEmpleados");

      if (listaEmpleadosJson) {
        listaEmpleadosJson = JSON.parse(listaEmpleadosJson);
        console.log(tiempoActual - listaEmpleadosJson.timestamp);
        if (tiempoActual - listaEmpleadosJson.timestamp > tiempoVigencia) {
          console.log("ya vencio");
          localStorage.removeItem("listaEmpleados");
        }
      }
      this.obtenerEmpleados(this.getUsuarioActual?.division);
    },
    cargarDatosTarjeta() {
      let tarjetaEncontrada = localStorage.getItem("tarjeta");

      if (tarjetaEncontrada) {
        tarjetaEncontrada = JSON.parse(tarjetaEncontrada);
        const fechaFormato = moment(tarjetaEncontrada.fechanacim).format(
          "DD/MM/YYYY"
        );

        this.nombre = tarjetaEncontrada.nombre;
        this.apellidoPaterno = tarjetaEncontrada.apellidoP;
        this.apellidoMaterno = tarjetaEncontrada.apellidoM;
        this.puntos = tarjetaEncontrada.saldoc;
        this.tarjeta = tarjetaEncontrada.tarjeta.trim();
        this.correoSender = tarjetaEncontrada.correoSender;
        this.nombreSender = tarjetaEncontrada.nombreSender;
        this.template = tarjetaEncontrada.template;
        this.titulo = tarjetaEncontrada.titulo;
        this.correo = tarjetaEncontrada.email;
        this.telefono = tarjetaEncontrada.telefono;
        this.codigoPostal = tarjetaEncontrada.cp;
        this.fechaNacimiento = fechaFormato;
        this.sexo = tarjetaEncontrada.sexo;
        this.verificado = tarjetaEncontrada.actualizado;
        this.fechaVerificacion = tarjetaEncontrada?.fechaVerificacion;
        this.cliente = tarjetaEncontrada?.cliente;
        (this.idCli = tarjetaEncontrada?.id_cli),
          (this.fechaAltat = tarjetaEncontrada?.fechaaltat);
        this.verificado =
          tarjetaEncontrada?.actualizado == "true" ? true : false;
        // this.fechaVerificacion = "01/08/2023";
        console.log(tarjetaEncontrada);
      } else {
        this.$router.push("/buscar");
      }
    },

    validarFormularioVerificacion() {
      this.botonDesactivado = true;
      const errores = [];
      const patronCorreo =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      const patronCodPostal = /^\d{5}$/;
      const patronFecha = /^\d{2}\/\d{2}\/\d{4}$/;

      if (this.nombre == "") {
        errores.push({ msg: "Proporcione un nombre" });
      }
      if (this.apellidoPaterno == "") {
        errores.push({ msg: "Proporcione un apellido paterno" });
      }
      if (this.apellidoMaterno == "") {
        errores.push({ msg: "Proporcione un apellido materno" });
      }
      if (this.telefono.toString() == "") {
        errores.push({ msg: "Proporcione un teléfono" });
      }

      if (this.correo == "") {
        errores.push({ msg: "Proporcione un correo" });
      }
      if (this.codigoPostal.toString() == "") {
        errores.push({ msg: "Proporcione un código postal" });
      }
      if (this.formatoFechaNacimiento == "") {
        errores.push({ msg: "Proporcione una fecha de nacimiento" });
      }
      if (this.tarjeta.toString() == "") {
        errores.push({ msg: "Proporcione una tarjeta" });
      }
      if (this.sexo == "") {
        errores.push({ msg: "Proporcione un sexo" });
      }

      if (
        isNaN(this.telefono.toString()) ||
        this.telefono.toString().length != 10
      ) {
        errores.push({ msg: "Teléfono inválido" });
      }

      if (!patronCorreo.test(this.correo)) {
        errores.push({ msg: "Correo inválido" });
      }

      if (!patronCodPostal.test(this.codigoPostal.toString())) {
        errores.push({ msg: "Código postal inválido" });
      }

      console.log(!patronFecha.test(this.formatoFechaNacimiento()));
      if (!patronFecha.test(this.fechaNacimiento)) {
        errores.push({ msg: "Introduce una fecha valida" });
      }

      if (!this.revisarMayoriaEdad(this.formatoFechaNacimiento())) {
        errores.push({ msg: "Para continuar necesitas ser mayor de edad" });
      }

      if (
        isNaN(this.tarjeta.toString()) ||
        this.tarjeta.toString().length != 13
      ) {
        errores.push({ msg: "Número de tarjeta inválido" });
      }

      if (isNaN(this.puntos.toString())) {
        errores.push({ msg: "Puntos inválidos" });
      }

      if (this.terminos == false) {
        errores.push({
          msg: "Para continuar es necesario aceptar los términos y condiciones",
        });
      }

      console.log(errores);
      if (errores.length > 0) {
        this.$swal({
          icon: "warning",
          type: "warning",
          title: "Oops...",
          text: errores[0].msg,
        });
        this.botonDesactivado = false;
      } else {
        this.botonDesactivado = false;
        this.procesarDatosValidados();
      }
    },
    async procesarDatosValidados() {
      this.botonDesactivado = false;
      const objetoDatosUsuario = {
        nombre: this.nombre,
        apellidoPaterno: this.apellidoPaterno,
        apellidoMaterno: this.apellidoMaterno,
        nombre: this.nombre,
        telefono: this.telefono.toString(),
        correo: this.correo,
        codigoPostal: this.codigoPostal.toString(),
        fechaNacimiento: this.formatoFechaNacimiento(),
        tarjeta: this.tarjeta.toString(),
        puntos: this.puntos.toString(),
        sexo: this.sexo,
        correoSender: this.correoSender,
        nombreSender: this.nombreSender,
        template: this.template,
        titulo: this.titulo,
        cliente: this.cliente,
        idCli: this.idCli.toString(),
        fechaAltat: this.fechaAltat,
        recomendo: this.recomendo,
      };
      localStorage.setItem("datosUsuario", JSON.stringify(objetoDatosUsuario));
      console.log(objetoDatosUsuario);

      this.resetearCampos();

      this.$router.push(this.urlSiguiente);
    },
    cancelarVerificacion() {
      this.resetearCampos();
      this.$router.go(-1);
    },

    resetearCampos() {
      this.nombre = "";
      this.telefono = "";
      this.correo = "";
      this.codigoPostal = "";
      this.fechaNacimiento = "";
      this.tarjeta = "";
      this.puntos = "";
      this.sexo = "";
      this.terminos = false;
    },
    revisarMayoriaEdad(fecha) {
      const fechaActual = moment().format("YYYY-MM-DD");
      const fechaActualOffsetTimestamp =
        parseInt(moment(fechaActual).format("x")) + 39600000 + 3540000;
      const fechaRevisar = moment(fecha).format("x");
      console.log(fechaActualOffsetTimestamp);
      console.log(fechaRevisar);
      // const fechaActual = new Date().getTime();
      // const fechaRevisar = new Date(fecha).getTime();
      // console.log(fechaActual);
      // console.log(fechaRevisar);
      console.log(fechaActualOffsetTimestamp - fechaRevisar);
      return fechaActualOffsetTimestamp - fechaRevisar >= 568067623175;
    },
    formatoFechaNacimiento() {
      console.log(this.fechaNacimiento);
      const arrayFecha = this.fechaNacimiento.split("/");
      const fechaFormatoMoment =
        arrayFecha[2] + "-" + arrayFecha[1] + "-" + arrayFecha[0];
      // console.log(fechaFormatoMoment);
      // const fechaRecibida = moment(fechaFormatoMoment);
      // console.log(fechaRecibida);
      // const fechaOffsetDia = parseInt(fechaRecibida.format('x')) - 86400000;
      // console.log(fechaOffsetDia)
      // const fechaFormato = moment(fechaOffsetDia).format("YYYY-MM-DD");
      console.log(fechaFormatoMoment);
      // console.log(this.fechaNacimiento);
      // const fechaFormato = new Date(this.fechaNacimiento);
      // console.log(fechaFormato);
      // const dia = fechaFormato.getDate().toString().padStart(2, "0");
      // console.log(dia);
      // const mes = (fechaFormato.getMonth() + 1).toString().padStart(2, "0");
      // const año = fechaFormato.getFullYear();
      // console.log(`${año}-${mes}-${dia+1}`);
      // return `${año}-${mes}-${dia}`;
      return fechaFormatoMoment;
    },
    darAutoFormatoFecha() {
      let fechaFormato = this.fechaNacimiento.replace(/\D/g, ""); // Elimina todos los caracteres no numéricos

      if (fechaFormato.length > 2) {
        fechaFormato =
          fechaFormato.substring(0, 2) + "/" + fechaFormato.substring(2);
      }
      if (fechaFormato.length > 5) {
        fechaFormato =
          fechaFormato.substring(0, 5) + "/" + fechaFormato.substring(5, 9);
      }

      this.fechaNacimiento = fechaFormato;
    },
    async obtenerEmpleados(sucursal) {
      try {
        let listaEmpleadosJson = localStorage.getItem("listaEmpleados");

        if (listaEmpleadosJson) {
          listaEmpleadosJson = JSON.parse(listaEmpleadosJson);
          for (var [key, cajera] of Object.entries(
            listaEmpleadosJson.listaEmpleados
          )) {
            this.listaEmpleados.push(
              `${cajera.name} ${cajera.lastnamefirst} ${cajera.lastnamesecond}`
            );
          }
          this.listaEmpleados.push("SIN RECOMENDACIÓN");
        } else {
          const listaEmpleadosResponse = await this.consultarEmpleados(sucursal);

          const timestamp = moment().format("x");

          const objetolistaEmpleados = {
            timestamp: timestamp,
            listaEmpleados: listaEmpleadosResponse,
          };

          localStorage.setItem(
            "listaEmpleados",
            JSON.stringify(objetolistaEmpleados)
          );

          for (var [key, cajera] of Object.entries(listaEmpleadosResponse)) {
            this.listaEmpleados.push(
              `${cajera.name} ${cajera.lastnamefirst} ${cajera.lastnamesecond}`
            );
          }
          this.listaEmpleados.push("SIN RECOMENDACIÓN");
        }

        console.log(this.listaEmpleados);
      } catch (error) {
        console.log(error);
        this.$swal({
          icon: error.code ? "error" : "warning",
          type: error.code ? "error" : "warning",
          title: error.code ? error.code : "Oops...",
          text: error.msg,
        });
      }
    },
  },
};
</script>
<template>
  <div class="container">
    <div class="page-title">
      <div class="row">
        <div class="col">
          <h3>Verificar datos</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="container d-flex justify-content-center">
    <div class="card w-100 px-1" style="max-width: 900px">
      <form @submit.prevent="validarFormularioVerificacion()">
        <div class="card-body">
          <div class="card mb-3" v-if="verificado == 1">
            <div
              class="card-body p-2 bg-primary rounded d-flex justify-content-between align-items-center"
              style="background-color: #10b981 !important"
            >
              <i class="icofont icofont-2x icofont-check-circled"></i>
              <p class="m-0 f-w-700" v-if="fechaVerificacion">
                Tarjetahabiente verificado el {{ fechaVerificacion }}
              </p>
              <p class="m-0 f-w-700" v-else>Tarjetahabiente verificado</p>
              <div></div>
            </div>
          </div>

          <div class="mb-3">
            <label class="col-form-label pt-0" for="nombre">Nombre</label>
            <input
              :disabled="!nombreEditable"
              class="form-control"
              id="nombre"
              type="text"
              aria-describedby="nombre"
              placeholder="Introduce tu nombre"
              v-model.trim="nombre"
            />
          </div>
          <div class="">
            <div class="row">
              <div class="col-lg col-md-12 mb-3">
                <label class="col-form-label pt-0" for="apellidoPaterno"
                  >Apellido paterno</label
                >
                <input
                  :disabled="!nombreEditable"
                  class="form-control"
                  id="apellidoPaterno"
                  type="text"
                  aria-describedby="apellidoPaterno"
                  placeholder="Introduce tu apellido paterno"
                  v-model.trim="apellidoPaterno"
                />
              </div>
              <div class="col-lg col-md-12 mb-3">
                <label class="col-form-label pt-0" for="apellidoMaterno"
                  >Apellido materno</label
                >
                <input
                  :disabled="!nombreEditable"
                  class="form-control"
                  id="apellidoMaterno"
                  type="text"
                  aria-describedby="apellidoMaterno"
                  placeholder="Introduce tu apellido materno"
                  v-model.trim="apellidoMaterno"
                />
              </div>
            </div>
          </div>
          <div class="">
            <div class="row">
              <div class="col-lg col-md-12 mb-3">
                <label class="col-form-label pt-0" for="tipo"
                  >Tipo de tarjeta</label
                >
                <input
                  disabled
                  class="form-control"
                  id="tipo"
                  type="text"
                  aria-describedby="tipo"
                  placeholder="Introduce el tipo de tarjeta"
                  v-model.trim="nombreSender"
                />
              </div>
              <div class="col-lg col-md-12 mb-3">
                <label class="col-form-label pt-0" for="telefono"
                  >Teléfono</label
                >
                <div class="row">
                  <div
                    class="col-lg-1 col-2 d-flex align-items-center justify-content-lg-start justify-content-center"
                    style="min-height: 100%"
                  >
                    <label class="col-form-label" for="prefijo">+52</label>
                  </div>
                  <div class="col-lg-11 col-10">
                    <input
                      class="form-control"
                      id="telefono"
                      type="number"
                      aria-describedby="telefono"
                      placeholder="Introduce tu teléfono"
                      v-model.trim="telefono"
                    />
                  </div>
                </div>
                <!-- <input
                    class="form-control"
                    id="telefono"
                    type="text"
                    aria-describedby="telefono"
                    placeholder="Introduce tu telefono"
                    v-model.trim="telefono"/> -->
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label class="col-form-label pt-0" for="correo"
              >Correo electrónico</label
            >
            <input
              class="form-control"
              id="correo"
              type="text"
              aria-describedby="correo"
              placeholder="Introduce tu correo electrónico"
              v-model.trim="correo"
              
            />
            <!-- :disabled="noCorreo" -->
            <!-- <div class="checkbox checkbox-dark m-square col-form-label ms-1">
              <input
                id="noCorreo"
                type="checkbox"
                @click="cambiarCorreoDefecto()"
                v-model.trim="noCorreo"
              />
              <label class="mt-0" for="noCorreo">No tengo correo</label>
            </div> -->
          </div>
          <div class="">
            <div class="row">
              <div class="col-lg col-md-12 mb-3">
                <label class="col-form-label pt-0" for="codigoPostal"
                  >Código postal</label
                >
                <input
                  class="form-control"
                  id="codifoPostal"
                  type="number"
                  aria-describedby="codigoPostal"
                  placeholder="Introduce tu código postal"
                  v-model.trim="codigoPostal"
                />
              </div>
              <div class="col-lg col-md-12 mb-3">
                <label class="col-form-label pt-0" for="fechaNacimiento"
                  >Fecha de nacimiento</label
                >
                <input
                  class="form-control"
                  id="fechaNacimiento"
                  type="tel"
                  aria-describedby="fechaNacimiento"
                  placeholder="DD/MM/AAAA"
                  v-model.trim="fechaNacimiento"
                  @input="darAutoFormatoFecha()"
                />
                <!-- <datepicker
                  id="fechaNacimiento"
                  class="datetimepicker-input digits"
                  :enable-time-picker="false"
                  type="date"
                  locale="es"
                  select-text="Confirmar"
                  cancel-text="Cancelar"
                  format="dd/MM/yyyy"
                  v-model.trim="fechaNacimiento"
                /> -->
              </div>
            </div>
          </div>
          <div class="">
            <div class="row">
              <div class="col-lg col-md-12 mb-3">
                <label class="col-form-label pt-0" for="tarjeta"
                  >Tarjeta club</label
                >
                <input
                  disabled
                  class="form-control"
                  id="tarjeta"
                  type="number"
                  aria-describedby="tarjeta"
                  placeholder="Introduce tu tarjeta club"
                  v-model.trim="tarjeta"
                />
              </div>
              <div class="col-lg col-md-12 mb-3">
                <label class="col-form-label pt-0" for="id_cli"
                  >Id cliente</label
                >
                <input
                  disabled
                  class="form-control"
                  id="id_cli"
                  type="number"
                  aria-describedby="id_cli"
                  placeholder="Introduce un id_cli"
                  v-model.trim="idCli"
                />
              </div>
            </div>
          </div>
          <div class="row mb-3" v-if="recomendado">
            <div class="col">
              <label class="form-label" for="exampleFormControlSelect30"
                >Recomendo</label
              >
              <select
                class="form-select digits"
                id="exampleFormControlSelect30"
                v-model="recomendo"
              >
                <option v-for="cajera in listaEmpleados">{{ cajera }}</option>
              </select>
            </div>
          </div>
          <div class="mb-3">
            <label class="col-form-label pt-0" for="puntos">Puntos</label>
            <input
              disabled
              class="form-control"
              id="puntos"
              type="number"
              aria-describedby="puntos"
              placeholder="Introduce tus puntos"
              v-model.trim="puntos"
            />
          </div>
          <div class="row">
            <div class="col-lg col-md-12 mb-3">
              <label class="col-form-label pt-0" for="sexo">Sexo</label>
              <div class="m-t-15 custom-radio-ml">
                <div class="form-check radio radio-primary">
                  <input
                    class="form-check-input"
                    id="masculino"
                    type="radio"
                    name="masculino"
                    value="Hombre"
                    v-model.trim="sexo"
                  />
                  <label class="form-check-label" for="masculino">Hombre</label>
                </div>
                <div class="form-check radio radio-primary">
                  <input
                    class="form-check-input"
                    id="femenino"
                    type="radio"
                    name="femenino"
                    value="Mujer"
                    v-model.trim="sexo"
                  />
                  <label class="form-check-label" for="femenino">Mujer</label>
                </div>
                <div class="form-check radio radio-primary">
                  <input
                    class="form-check-input"
                    id="sinEspecificar"
                    type="radio"
                    name="sinEspecificar"
                    value="Otro"
                    v-model.trim="sexo"
                  />
                  <label class="form-check-label" for="sinEspecificar"
                    >Sin especificar</label
                  >
                </div>
              </div>
            </div>
            <div class="col-lg col-md-12 d-flex align-items-center">
              <div class="checkbox checkbox-dark m-square">
                <div class="d-flex justify-content-start ms-1">
                  <input
                    id="terminos"
                    type="checkbox"
                    v-model.trim="terminos"
                  />
                  <label class="mt-0" for="terminos"
                    >Acepto los términos y condiciones</label
                  >
                </div>
                <h6 class="f-w-600 ms-1">
                  <a
                    target="_blank"
                    href="https://www.superkompras.com.mx/aviso-privacidad/"
                    >Consulta nuestro aviso de privacidad</a
                  >
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer text-end">
          <button
            class="btn btn-secondary me-2"
            type="button"
            @click="cancelarVerificacion()"
          >
            Cancelar
          </button>
          <button class="btn btn-primary" type="submit">Continuar</button>
        </div>
      </form>
    </div>
  </div>
  <!-- No Correo: {{ noCorreo }} -->
  <!-- Nombre: {{ nombre }}
ApellidoPaterno: {{ apellidoPaterno }}
ApellidoMaterno: {{ apellidoMaterno }}
Telefono: {{ telefono }}
Correo: {{ correo }}
Codigo postal: {{ codigoPostal }}
Fecha de nacimiento: {{ fechaNacimiento }}>
FORMATO Fecha de nacimiento: {{ formatofechaNacimiento }}>
Tarjeta: {{ tarjeta }}
Puntos: {{ puntos }}
Sexo: {{ sexo }}
Terminos: {{ terminos }} -->
</template>
<style>
.dp__input_wrap input {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
